import BasePlugin from '../BasePlugin'

export default class ExportPrerequisitesFromRequest extends BasePlugin {
  async execute () {
    let requestId = this.context.getModel().id
    this.send({ requestId: requestId })
      .then((response) => {
        let address = `${this.context.$config.api}/etleditor/export/24`
        this.context.$http({
          method: 'post',
          url: address,
          responseType: 'arraybuffer',
          data: { 'ids': response.data }
        }).then(function (response2) {
          let blob = new Blob([response2.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          let url = window.URL.createObjectURL(blob)
          window.open(url)
        })
      })
  }
}
